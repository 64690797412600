'use es6';

import { Image } from 'foundations-components/transitional/components/Image';
import { Clickable } from 'foundations-components/transitional/components/Clickable';
import { Text } from 'foundations-components/components/Text';
import styled from 'styled-components';
export const ConnectProviderButtonImage = styled(Image).attrs({
  responsive: false
}).withConfig({
  displayName: "ConnectProviderButton__ConnectProviderButtonImage",
  componentId: "sc-1u66wop-0"
})(["height:50px;"]);
export const ConnectProviderButtonTitle = styled(Text).attrs({
  as: 'h3'
}).withConfig({
  displayName: "ConnectProviderButton__ConnectProviderButtonTitle",
  componentId: "sc-1u66wop-1"
})([""]);
export const ConnectProviderButton = styled(Clickable)(({
  theme
}) => ({
  borderColor: theme.color['border-core-default'],
  borderRadius: theme.space['100'],
  borderWidth: theme.space['25'],
  borderStyle: 'solid',
  padding: `${theme.space['300']} ${theme.space['600']}`,
  flex: '1 0 0px' /* stylelint-disable-line length-zero-no-unit */,
  display: 'flex',
  flexDirection: 'column',
  [`${ConnectProviderButtonImage}`]: {
    flex: '0 0 auto',
    alignSelf: 'center'
  },
  [`${ConnectProviderButtonTitle}`]: {
    flex: '1 0 auto',
    marginTop: theme.space['300'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  '&:hover': {
    boxShadow: `0 0 0 1px ${theme.color['border-core-hover']}, 0 0 12px 0 ${theme.color['border-core-hover']}`
  }
}));