export const referenceToOption = reference => {
  return Object.assign({
    disabled: reference.disabled,
    help: reference.description,
    text: reference.label,
    value: reference.id
  }, reference.icon && {
    imageUrl: reference.icon.src
  });
};
export const referenceToOptionV2 = reference => {
  // TODO: CONFIRM THIS
  return Object.assign({
    disabled: reference.disabled,
    help: reference.description,
    text: reference.label,
    value: reference.id
  }, reference.icon && {
    imageUrl: reference.icon.src
  });
};