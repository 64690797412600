import isNumber from 'transmute/isNumber';
import uniqueId from 'transmute/uniqueId';
import { getCurrencyPriceProperty, isCurrencyPriceProperty } from 'customer-data-objects/lineItem/PropertyNames';
import { getProperty as getObjectProperty, setProperty } from 'customer-data-objects/objects/ObjectAccessors';
import calculateExchangeRatePrice from './calculations/calculateExchangeRatePrice';
import { convertToValue } from './propertyValue';
import { getId } from './subject';

// TODO: Remove this implementation after getObjectProperty supports null
export const getProperty = (...args) => getObjectProperty(...args);
export function getTemporaryLineItemId() {
  return Number(uniqueId('-'));
}
export function convertProductToLineItem(product, currency, multiCurrencies, defaultCurrencyCode, globalTerms = {}) {
  const productId = getId(product);
  const productWithoutCurrencyPrices = Object.assign({}, product, {
    properties: Object.fromEntries(Object.entries(product.properties).filter(([name]) => !isCurrencyPriceProperty(name) && !['createdate', 'hs_status'].includes(name)))
  });

  // Create the initial line item
  const lineItem = Object.assign({}, productWithoutCurrencyPrices, {
    objectId: getTemporaryLineItemId(),
    isNew: true
  });

  // Prepare all properties to set at once
  const propertiesToSet = [{
    name: 'hs_product_id',
    value: String(productId)
  }, {
    name: 'quantity',
    value: '1'
  }, {
    name: 'hs_line_item_currency_code',
    value: currency
  }];

  // Add price and handle unit cost conversion if currency exists
  if (currency) {
    propertiesToSet.push({
      name: 'price',
      value: getProperty(product, getCurrencyPriceProperty(currency))
    });

    // Convert unit cost to deal currency if necessary
    const unitCost = Number(getProperty(lineItem, 'hs_cost_of_goods_sold'));
    if (isNumber(unitCost) && multiCurrencies.length > 0) {
      const unitCostInDealCurrency = calculateExchangeRatePrice(multiCurrencies, defaultCurrencyCode, currency, unitCost);
      propertiesToSet.push({
        name: 'hs_cost_of_goods_sold',
        value: convertToValue(unitCostInDealCurrency)
      });
    }
  }

  // Apply all standard properties
  const lineItemWithProperties = propertiesToSet.reduce((acc, {
    name,
    value
  }) => setProperty(acc, name, value), lineItem);

  // Apply global terms
  return Object.entries(globalTerms).reduce((acc, [key, globalTerm]) => setProperty(acc, key, globalTerm), lineItemWithProperties);
}
export function convertProductsToLineItems(products, currency, multiCurrencies, defaultCurrencyCode, globalTerms) {
  return products.map(product => convertProductToLineItem(product, currency, multiCurrencies, defaultCurrencyCode, globalTerms));
}