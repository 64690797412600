import { useEffect, useMemo, useState, useRef } from 'react';
import { ModalMessage } from './IFrameContextHandler';
// Straight from https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
function usePrevious(value) {
  const ref = useRef(undefined);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export const AppType = Object.freeze({
  SUBSCRIPTIONS_ADMIN: 'subscriptions-admin',
  BILLING_WIZARD: 'billing-wizard'
});
export function useIFramedApplication({
  isOpen,
  onClose,
  initializeArgs,
  appType
}) {
  const [contextHandler, setContextHandler] = useState(null);
  const previouslyOpen = usePrevious(isOpen);
  const [gotOpenSignalBeforeContextExisted, setGotOpenSignalBeforeContextExisted] = useState(false);
  useEffect(() => {
    if (contextHandler && !isOpen) {
      setContextHandler(null);
    }
    if (contextHandler && gotOpenSignalBeforeContextExisted) {
      const message = Object.assign({
        type: ModalMessage.OPEN_MODAL
      }, initializeArgs);
      contextHandler.sendMessage(message.type, message);
      setGotOpenSignalBeforeContextExisted(false);
    }
    if (!contextHandler) {
      // if isOpen == true and we don't yet have a contextHandler, we set the
      // gotOpenSignalBeforeContextExisted flag so we can handle it as soon as we get a context.
      if (isOpen) {
        setGotOpenSignalBeforeContextExisted(true);
      }
      return;
    }
    const justOpened = !previouslyOpen && isOpen;
    const justClosed = previouslyOpen && !isOpen;
    if (justOpened) {
      const message = Object.assign({
        type: ModalMessage.OPEN_MODAL
      }, initializeArgs);
      contextHandler.sendMessage(message.type, message);
      return;
    }
    if (justClosed) {
      const message = {
        type: ModalMessage.CLOSE_MODAL
      };
      contextHandler.sendMessage(message.type, message);
      return;
    }
  }, [isOpen, previouslyOpen, initializeArgs, contextHandler, gotOpenSignalBeforeContextExisted]);
  return useMemo(() => {
    const handleClose = changes => {
      var _contextHandler$sendM;
      contextHandler === null || contextHandler === void 0 || (_contextHandler$sendM = contextHandler.sendMessage) === null || _contextHandler$sendM === void 0 || _contextHandler$sendM.call(contextHandler, ModalMessage.CLOSE_MODAL, undefined);
      switch (appType) {
        case AppType.SUBSCRIPTIONS_ADMIN:
          onClose(changes);
          break;
        case AppType.BILLING_WIZARD:
          onClose(changes);
          break;
        default:
          throw new Error(`Unknown app type: ${appType}`);
      }
    };
    return {
      isOpen,
      contextHandler,
      handleClose,
      handleMessage(message) {
        switch (message.payload.type) {
          case ModalMessage.SUBMITTED_MODAL:
            handleClose(message.payload);
            break;
          default:
            return;
        }
      },
      handleError(error) {
        console.error(error);
        handleClose({
          error
        });
      },
      handleOnReady(newContext) {
        setContextHandler(newContext);
      }
    };
  }, [contextHandler, isOpen, onClose, appType]);
}