export const INVOICE_START_TYPE = {
  GENERATE_INVOICE: 'generateInvoice',
  CREATE_INVOICE: 'createInvoice'
};
export const INVOICE_OPERATION = {
  CREATE_BLANK_INVOICE: 'CREATE_BLANK_INVOICE'
};
export const INVOICE_STATUS = {
  DRAFT: 'draft',
  OPEN: 'open',
  PAID: 'paid',
  VOIDED: 'voided'
};
export const INVOICE_PAYMENT_METHOD = {
  MANUAL: 'manual'
};
export const ANCHOR_TAG_LINK = {
  PAYMENTS_GET_SETUP: 'paymentsGetSetUp'
};