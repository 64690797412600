import { useCallback, useState, useMemo } from 'react';
import { useAsyncEffect } from 'react-utils/hooks/useAsyncEffect';
import { fetchCrmSearch } from '../api/crmSearchApi';
import { registerQuery, useQuery } from 'data-fetching-client';
import { events } from '../usage-tracker/events';
import { CommerceTool } from '../types/CommerceTool';
import { track } from '../usage-tracker/track';
import { fetchHubUserSetting, saveHubUserSetting } from '../api/hubUserSettingsApi';
import { fetchHubSetting, saveHubSetting } from '../api/hubSettingsApi';
import { fetchDoesPortalHaveUSCompanyAddress } from '../api/companyDetailsApi';
import Raven from 'raven-js';
const MIN_TOOL_INSTANCES_INCLUSIVE = 1;
const MAX_TOOL_INSTANCES_EXCLUSIVE = 6;
const TOOL_COUNT_QUERY = registerQuery({
  fieldName: 'toolCount',
  args: ['query'],
  fetcher: async ({
    query
  }) => {
    const {
      total
    } = await fetchCrmSearch(query);
    return total;
  }
});
function mapCommerceToolToObjectType(toolName) {
  switch (toolName) {
    case CommerceTool.INVOICES:
      return 'INVOICE';
    case CommerceTool.PAYMENT_LINKS:
      return 'PAYMENT_LINK';
    case CommerceTool.SUBSCRIPTIONS:
      return 'SUBSCRIPTION';
    default:
      throw new Error(`Unexpected CommerceTool provided: ${toolName}`);
  }
}
function getFilterGroups(toolName) {
  switch (toolName) {
    case CommerceTool.INVOICES:
      return [{
        filters: [{
          property: 'hs_invoice_status',
          operator: 'NEQ',
          value: 'draft'
        }]
      }];
    default:
      return [];
  }
}
const useToolCount = (toolName, skip) => {
  const [totalToolCount, setTotalToolCount] = useState(0);
  const [finalizedToolCount, setFinalizedToolCount] = useState(0);
  const totalToolCountQuery = useMemo(() => {
    return {
      objectTypeId: mapCommerceToolToObjectType(toolName)
    };
  }, [toolName]);
  const finalizedToolCountQuery = useMemo(() => {
    return {
      objectTypeId: mapCommerceToolToObjectType(toolName),
      filterGroups: getFilterGroups(toolName)
    };
  }, [toolName]);
  const {
    loading: totalToolCountLoading,
    refetch: refetchTotalToolCount
  } = useQuery(TOOL_COUNT_QUERY, {
    variables: {
      query: totalToolCountQuery
    },
    skip,
    onCompleted: data => {
      var _data$toolCount;
      setTotalToolCount((_data$toolCount = data === null || data === void 0 ? void 0 : data.toolCount) !== null && _data$toolCount !== void 0 ? _data$toolCount : 0);
    },
    onError: error => {
      console.error(error);
    }
  });
  const {
    loading: finalizedToolCountLoading,
    refetch: refetchFinalizedToolCount
  } = useQuery(TOOL_COUNT_QUERY, {
    variables: {
      query: finalizedToolCountQuery
    },
    skip,
    onCompleted: data => {
      var _data$toolCount2;
      setFinalizedToolCount((_data$toolCount2 = data === null || data === void 0 ? void 0 : data.toolCount) !== null && _data$toolCount2 !== void 0 ? _data$toolCount2 : 0);
    },
    onError: error => {
      console.error(error);
    }
  });
  useAsyncEffect(async () => {
    if (!skip) {
      await Promise.all([refetchTotalToolCount(), refetchFinalizedToolCount()]);
    }
  }, [skip, refetchTotalToolCount, refetchFinalizedToolCount]);
  const loading = totalToolCountLoading || finalizedToolCountLoading;
  return [loading, totalToolCount, finalizedToolCount];
};
const useModal = toolName => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = source => {
    track(events.commerceSpecialistsInteraction, {
      action: 'commerceSpecialistsContactAlertInteraction',
      subAction: 'openContactModal',
      app: toolName,
      source
    });
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return {
    modalOpen,
    openModal,
    closeModal
  };
};
const useHasViewedSpecialistsContactModal = (toolName, skipFetch = false) => {
  const [loading, setLoading] = useState(false);
  const [hasViewedModal, setHasViewedModal] = useState(false);
  useAsyncEffect(async () => {
    if (!skipFetch) {
      setLoading(true);
      const value = await fetchHubUserSetting(`CommerceGrowth:HasViewedSpecialistsContactModal:${toolName}`, 'false');
      setHasViewedModal(value === 'true');
      setLoading(false);
    }
  }, [toolName, skipFetch]);
  const writeHasViewedModal = useCallback(async () => {
    await saveHubUserSetting(`CommerceGrowth:HasViewedSpecialistsContactModal:${toolName}`, 'true');
    setHasViewedModal(true);
  }, [toolName]);
  return [loading, hasViewedModal, writeHasViewedModal];
};
const usePortalIsInUS = hasCommerceSpecialistsScope => {
  const [portalIsInUS, setPortalIsInUS] = useState(false);
  const [portalIsInUSLoading, setPortalIsInUSLoading] = useState(hasCommerceSpecialistsScope);
  useAsyncEffect(async () => {
    if (hasCommerceSpecialistsScope) {
      try {
        const isInUS = await fetchDoesPortalHaveUSCompanyAddress();
        setPortalIsInUS(isInUS);
      } catch (e) {
        Raven.captureException(e);
      } finally {
        setPortalIsInUSLoading(false);
      }
    }
  }, [hasCommerceSpecialistsScope]);
  return [portalIsInUSLoading, portalIsInUS];
};
const useIneligibleForSpecialistsContact = ({
  toolName,
  hasCommerceSpecialistsScope = true
}) => {
  const [openModalLinkAvailable, setOpenModalLinkAvailable] = useState(false);
  const [ineligibleForSpecialistsContact, setIneligibleForSpecialistsContact] = useState(false);
  const [userPreferenceLoading, setUserPreferenceLoading] = useState(false);
  const hubSettingKey = `CommerceGrowth:IneligibleForSpecialistsContact:${toolName}`;
  useAsyncEffect(async () => {
    if (hasCommerceSpecialistsScope) {
      setUserPreferenceLoading(true);
      const value = await fetchHubSetting(hubSettingKey, 'false');
      setIneligibleForSpecialistsContact(value === 'true');
      setUserPreferenceLoading(false);
    }
  }, [hubSettingKey, hasCommerceSpecialistsScope]);
  const skipFetchFinalizedToolCount = !hasCommerceSpecialistsScope || ineligibleForSpecialistsContact;
  const [toolCountLoading, totalToolCount, finalizedToolCount] = useToolCount(toolName, skipFetchFinalizedToolCount);
  const loading = userPreferenceLoading || toolCountLoading;
  useAsyncEffect(async () => {
    if (!loading && !ineligibleForSpecialistsContact) {
      if (finalizedToolCount >= MAX_TOOL_INSTANCES_EXCLUSIVE) {
        await saveHubSetting(hubSettingKey, 'true');
      } else if (totalToolCount >= MIN_TOOL_INSTANCES_INCLUSIVE) {
        setOpenModalLinkAvailable(true);
      }
    }
  }, [loading, ineligibleForSpecialistsContact, totalToolCount, finalizedToolCount, hubSettingKey]);
  return [loading, ineligibleForSpecialistsContact, openModalLinkAvailable];
};
const useCommerceSpecialistsContactAvailability = (toolName, _hasAllGates, hasAllScopes) => {
  const hasCommerceSpecialistsScope = hasAllScopes('commerce-specialists-growth-contact-access');
  const [ineligibleForSpecialistsContactLoading, ineligibleForSpecialistsContact, openModalLinkAvailable] = useIneligibleForSpecialistsContact({
    toolName,
    hasCommerceSpecialistsScope
  });
  const [portalIsInUSLoading, portalIsInUS] = usePortalIsInUS(hasCommerceSpecialistsScope);
  const available = !ineligibleForSpecialistsContact && hasCommerceSpecialistsScope && portalIsInUS;
  const loading = ineligibleForSpecialistsContactLoading || portalIsInUSLoading;
  const showOpenModalLink = openModalLinkAvailable && available;
  return [loading, available, showOpenModalLink];
};
export const useCommerceSpecialistsContact = (toolName, hasAllGates, hasAllScopes) => {
  const [availableLoading, available, showOpenModalLink] = useCommerceSpecialistsContactAvailability(toolName, hasAllGates, hasAllScopes);
  const [hasViewedSpecialistsContactModalLoading, hasViewedSpecialistsContactModal, writeHasViewedSpecialistsContactModal] = useHasViewedSpecialistsContactModal(toolName, availableLoading || !available);
  const loading = availableLoading || hasViewedSpecialistsContactModalLoading;
  const showModalOnEditorAbandon = !hasViewedSpecialistsContactModal && available;
  const {
    modalOpen,
    openModal,
    closeModal
  } = useModal(toolName);
  return {
    loading,
    showOpenModalLink,
    showModalOnEditorAbandon,
    modalOpen,
    openModal: (source, setViewedModalPreference) => {
      openModal(source);
      if (setViewedModalPreference) {
        writeHasViewedSpecialistsContactModal().catch(console.error);
      }
    },
    closeModal
  };
};
export const useCommerceHomeSpecialistsContact = (toolName, hasAllGates, hasAllScopes) => {
  const hasCommerceSpecialistsScope = hasAllScopes('commerce-specialists-growth-contact-access');
  const {
    modalOpen,
    openModal,
    closeModal
  } = useModal(toolName);
  const [loading, eligible] = usePortalIsInUS(hasCommerceSpecialistsScope);
  return {
    loading,
    eligible,
    modalOpen,
    openModal,
    closeModal
  };
};