import PortalIdParser from 'PortalIdParser';
export const COMMERCE_HUB_LINK = `/commerce/${PortalIdParser.get()}`;
export const EDIT_PROPERTIES_LINK = `/property-settings/${PortalIdParser.get()}/properties?type=0-69`;
export const MANAGE_DEFAULT_SETTINGS_LINK = `/settings/${PortalIdParser.get()}/sales/subscriptions/default-settings`;

/**
 * Keys used by other apps to store a flag in sessionStorage that indicate the user
 * wants to deep links to a specific behavior components from this lib are used
 */
export const DEEP_LINK_KEYS = {
  SUBSCRIPTION_CREATE_FLOW: 'hubSpotCommerceSubscriptionLibSubscriptionCreateFlowDeepLink'
};