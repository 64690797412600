import { gql, useQuery } from '@apollo/client';
import { useRetry } from '../../../hooks/useRetry';
import { handleError } from '../../../utils/errorHandling';
const FETCH_UPDATE_PAYMENT_METHOD_PROPERTIES = ("__gql__", "{\"kind\":\"Document\",\"definitions\":[{\"kind\":\"OperationDefinition\",\"operation\":\"query\",\"name\":{\"kind\":\"Name\",\"value\":\"FetchUpdatePaymentMethodProperties\"},\"variableDefinitions\":[{\"kind\":\"VariableDefinition\",\"variable\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}},\"type\":{\"kind\":\"NonNullType\",\"type\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"Long\"}}}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"crmObject\"},\"arguments\":[{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"},\"value\":{\"kind\":\"Variable\",\"name\":{\"kind\":\"Name\",\"value\":\"objectId\"}}},{\"kind\":\"Argument\",\"name\":{\"kind\":\"Name\",\"value\":\"type\"},\"value\":{\"kind\":\"StringValue\",\"value\":\"0-69\",\"block\":false}}],\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"id\"}},{\"kind\":\"InlineFragment\",\"typeCondition\":{\"kind\":\"NamedType\",\"name\":{\"kind\":\"Name\",\"value\":\"PaymentsSubscription\"}},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"paymentsObjectsForOrder\"},\"selectionSet\":{\"kind\":\"SelectionSet\",\"selections\":[{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"anyPastDue\"}},{\"kind\":\"Field\",\"name\":{\"kind\":\"Name\",\"value\":\"subscriptionNames\"}}]}}]}}]}}]}}]}", {
  id: null,
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchUpdatePaymentMethodProperties"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "objectId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Long"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "crmObject"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "id"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "objectId"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "type"
          },
          value: {
            kind: "StringValue",
            value: "0-69",
            block: false
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "InlineFragment",
            typeCondition: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "PaymentsSubscription"
              }
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "paymentsObjectsForOrder"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyPastDue"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "subscriptionNames"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
});
export function isUpdatePaymentMethodQueryValid(data) {
  var _data$crmObject;
  return Boolean((data === null || data === void 0 || (_data$crmObject = data.crmObject) === null || _data$crmObject === void 0 ? void 0 : _data$crmObject.__typename) === 'PaymentsSubscription' && data.crmObject.paymentsObjectsForOrder);
}
export function useUpdatePaymentMethodQuery(objectId) {
  const query = useQuery(FETCH_UPDATE_PAYMENT_METHOD_PROPERTIES, {
    variables: {
      objectId
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const startRetrying = useRetry({
    retry: () => {
      query.refetch().catch(handleError);
    },
    shouldRetry: () => Boolean(!isUpdatePaymentMethodQueryValid(query.data) || query.error),
    onFailedAfterRetries: () => {
      throw new Error('The UpdatePaymentMethodModal failed to finish loading after retries');
    }
  });
  if (query.loading || !query.data) {
    return Object.assign({}, query, {
      data: null
    });
  }
  if (!isUpdatePaymentMethodQueryValid(query === null || query === void 0 ? void 0 : query.data) || query.error) {
    startRetrying();
    return Object.assign({}, query, {
      data: null
    });
  }
  const {
    crmObject: {
      paymentsObjectsForOrder
    }
  } = query.data;
  return Object.assign({}, query, {
    data: {
      order: paymentsObjectsForOrder
    }
  });
}