import { DOCUMENT_TOKEN_REGEX, DOCUMENT_LINK_REGEX } from '../../draft-transplant/utils/constants';
export const buildDocumentTags = (documentId, requireEmail) => {
  const skipForm = !requireEmail;
  const documentIfTag = `{{ if custom.documentexists_${documentId} }}`;
  const documentEndTag = `{{ end }}`;
  const documentTokenTag = `custom.documentlink_id_${documentId}_skipform_${skipForm}`;
  return {
    documentIfTag,
    documentEndTag,
    documentTokenTag
  };
};

// this function is used to find the token within the html and
// it's used when the token tag is not provided
export const findTokenTag = html => {
  const matches = DOCUMENT_LINK_REGEX.exec(html);
  // if there is no match with the regex, it would be the token only in the dom element
  return matches ? matches[2] : html;
};
const buildDocumentPreviewTableCell = ({
  previewTitle,
  description,
  documentTokenTag
}) => {
  if (description) {
    return ['td', {
      style: 'padding-left:8px;text-align:left'
    }, ['h2', {
      class: 'document-link-preview-name',
      style: 'margin-top:0;margin-bottom:8px'
    }, previewTitle], ['p', {
      class: 'document-link-preview-description',
      style: 'margin-bottom:8px'
    }, description], ['a', {
      class: 'document-link-preview-link',
      href: `${documentTokenTag}`
    }, `${documentTokenTag}`]];
  }
  return ['td', {
    style: 'padding-left:8px;text-align:left'
  }, ['h2', {
    class: 'document-link-preview-name',
    style: 'margin-top:0;margin-bottom:8px'
  }, previewTitle], ['a', {
    class: 'document-link-preview-link',
    href: `${documentTokenTag}`
  }, `${documentTokenTag}`]];
};
const buildDocumentPreviewTable = ({
  documentId,
  previewImage,
  previewTitle,
  description,
  documentTokenTag
}) => {
  const previewTableAttrs = {
    class: 'document-link-preview-table',
    style: 'cursor:pointer;width:400px;border:solid 1px #00a4bd;padding:8px;border-radius:2px',
    'data-hs-document-link-preview-id': documentId
  };
  const previewImageAttrs = {
    src: previewImage,
    alt: previewTitle,
    class: 'document-link-preview-image',
    style: 'display:block;margin:0 auto;max-width:150px;max-height:150px'
  };
  return ['table', previewTableAttrs, ['tbody', {}, ['tr', {}, ['td', {
    style: 'text-align:center'
  }, ['img', previewImageAttrs]], buildDocumentPreviewTableCell({
    previewTitle,
    description,
    documentTokenTag
  })]]];
};
export const buildDocumentPreviewDom = ({
  documentId,
  documentName,
  requireEmail,
  previewTitle,
  previewImage,
  description,
  tokenTag
}) => {
  const {
    documentIfTag,
    documentEndTag
  } = buildDocumentTags(documentId, requireEmail);
  const isUnresolvedToken = DOCUMENT_TOKEN_REGEX.test(tokenTag);
  const wrapperLinkAttrs = {
    href: `${tokenTag}`,
    target: '_blank',
    rel: 'noreferrer noopener',
    style: 'display:inline-block;text-decoration:none'
  };
  return ['span', {
    'data-hs-document-preview': 'true',
    'document-id': documentId,
    'document-name': documentName,
    'require-email': requireEmail,
    'preview-title': previewTitle,
    'preview-image': previewImage,
    description,
    'token-tag': tokenTag
  }, `${isUnresolvedToken ? documentIfTag : ''}`, ['span', {}, ['figure', {
    class: 'document-link-plugin'
  }, ['a', wrapperLinkAttrs, buildDocumentPreviewTable({
    documentId,
    previewImage,
    previewTitle,
    description,
    documentTokenTag: tokenTag
  })]]], `${isUnresolvedToken ? documentEndTag : ''}`];
};