import { useContext, useEffect, useId } from 'react';
import { AIPropertiesProcessingContext } from './AIPropertiesProcessingContextProvider';
import { makeIdentifierKey } from './identifierKey';

/**
 * Returns a polling status for a property that is being filled in the
 * background. By default, this polls for a new status and automatically
 * updates the value when the status comes back.
 */
export function useAIProcessingStatus(objectTypeId, propertyName, objectId, {
  pollForStatus = true
} = {}) {
  var _metadataEntry$status, _metadataEntry$isLoad;
  const id = useId();
  const key = objectId ? makeIdentifierKey(objectTypeId, propertyName, objectId) : null;
  const context = useContext(AIPropertiesProcessingContext);
  const metadataEntry = key ? context === null || context === void 0 ? void 0 : context.metadata[key] : null;
  const processingStatus = (_metadataEntry$status = metadataEntry === null || metadataEntry === void 0 ? void 0 : metadataEntry.status) !== null && _metadataEntry$status !== void 0 ? _metadataEntry$status : null;
  const isLoadingNewValue = (_metadataEntry$isLoad = metadataEntry === null || metadataEntry === void 0 ? void 0 : metadataEntry.isLoadingNewValue) !== null && _metadataEntry$isLoad !== void 0 ? _metadataEntry$isLoad : false;
  useEffect(() => {
    if (!context || !pollForStatus || !objectId) {
      return;
    }
    if (processingStatus === 'pending') {
      context.startPollingAndAttach(id, objectTypeId, propertyName, objectId);
    }
    return () => {
      context.detachPolling(id, objectTypeId, propertyName, objectId);
    };
  }, [context, id, objectId, objectTypeId, pollForStatus, processingStatus, propertyName]);
  return isLoadingNewValue ? 'pending' : processingStatus;
}