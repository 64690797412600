import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useCallback } from 'react';
import { markActionComplete } from 'user-context/onboarding';
import { useObjectTypeId } from './useObjectTypeId';
import { useProperty } from './useProperty';
export const useTicketStatusChangeTracking = onChange => {
  const objectTypeId = useObjectTypeId();
  const property = useProperty();
  const onChangeWithTicketStatusTracking = useCallback(changes => {
    if (objectTypeId === TICKET_TYPE_ID && property.name === 'hs_pipeline_stage') {
      markActionComplete('update-ticket-status').catch(() => {});
    }
    onChange(changes);
  }, [onChange, objectTypeId, property.name]);
  return onChangeWithTicketStatusTracking;
};