export default function getChargePaymentMethodFailureMessage(errorCode) {
  switch (errorCode) {
    case 'insufficient_funds':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.insufficient_funds';
    case 'payment_method_skip_verification_failed':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.payment_method_skip_verification_failed';
    case 'no_account':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.no_account';
    case 'debit_not_authorized':
    case 'debit_authorization_stopped':
    case 'debit_authorization_revoked':
    case 'revocation_of_authorization':
    case 'debit_disputed':
    case 'authorization_revoked':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.debit_not_authorized';
    case 'bank_account_unusable':
    case 'account_frozen':
    case 'bank_account_restricted':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.bank_account_unusable';
    case 'invalid_account_number':
    case 'branch_does_not_exist':
    case 'incorrect_account_holder_name':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.invalid_account_number';
    case 'account_closed':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.account_closed';
    case 'invalid_currency':
    case 'currency_not_supported':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.invalid_currency';
    case 'do_not_honor':
    case 'try_again_later':
    case 'transaction_not_allowed':
    case 'generic_decline':
    case 'previously_declined_do_not_retry':
    case 'processing_error':
    case 'pickup_card':
    case 'reenter_transaction':
    case 'refer_to_customer':
    case 'generic_could_not_process':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.generic_decline';
    case 'incorrect_cvc':
    case 'invalid_cvc':
    case 'requested_block_on_incorrect_cvc':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.incorrect_cvc';
    case 'invalid_account':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.invalid_account';
    case 'incorrect_number':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.incorrect_number';
    case 'expired_card':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.expired_card';
    case 'card_velocity_exceeded':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.card_velocity_exceeded';
    case 'stolen_card':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.stolen_card';
    case 'invalid_amount':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.invalid_amount';
    case 'requested_block_on_incorrect_zip':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.requested_block_on_incorrect_zip';
    case 'lost_card':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.lost_card';
    case 'invalid_pin':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.invalid_pin';
    case 'debit_authorization_not_match':
    case 'recipient_deceased':
    case 'bank_ownership_changed':
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.debit_authorization_not_match';
    case 'invalid_request_error':
    default:
      return 'commerceSharedComponents.ChargePaymentMethodPanel.failureModal.failureReason.default';
  }
}