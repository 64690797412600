import merge from 'hs-lodash/merge';
import PortalIdParser from 'PortalIdParser';
import I18n from 'I18n';
export const makeInvoice = invoice => {
  const defaultInvoice = {
    associatedObjects: {
      INVOICE_TO_DEAL: [],
      INVOICE_TO_LINE_ITEM: [],
      INVOICE_TO_CONTACT: [],
      INVOICE_TO_COMPANY: [],
      INVOICE_TO_QUOTE: [],
      INVOICE_TO_FEE: [],
      INVOICE_TO_DISCOUNT: [],
      INVOICE_TO_TAX: [],
      INVOICE_TO_TICKET: []
    },
    objectId: null,
    objectTypeId: '0-53',
    portalId: PortalIdParser.get(),
    properties: {}
  };
  return merge(defaultInvoice, invoice);
};
export const makeContact = contact => {
  return merge({
    objectId: null,
    objectTypeId: '0-1',
    portalId: PortalIdParser.get(),
    properties: {}
  }, contact);
};
export const makeLineItem = lineItem => {
  return merge({
    objectId: null,
    objectTypeId: '0-8',
    portalId: PortalIdParser.get(),
    properties: {}
  }, lineItem);
};
export const makeProperty = (name, value) => ({
  name,
  value
});
export const mapProperties = properties => Object.entries(properties).map(([name, property]) => ({
  name,
  value: property.value
}));
export const unmapProperties = properties => Object.fromEntries(properties.map(property => [property.name, property]));
export const mapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: mapProperties(associatedObject.properties)
});
export const unmapAssociatedObject = associatedObject => Object.assign({}, associatedObject, {
  properties: unmapProperties(associatedObject.properties)
});
export const createInvoicePayload = invoice => Object.assign({}, invoice, {
  associatedObjects: Object.fromEntries(Object.entries(invoice.associatedObjects).map(([associationKey, associatedObjects]) => [associationKey, associatedObjects.map(mapAssociatedObject)])),
  properties: mapProperties(invoice.properties)
});
export const createInvoiceFromInvoiceResponse = invoiceResponse => makeInvoice(Object.assign({}, invoiceResponse, {
  properties: unmapProperties(invoiceResponse.properties),
  associatedObjects: Object.fromEntries(Object.entries(invoiceResponse.associatedObjects).map(([associationKey, associatedObjects]) => [associationKey, associatedObjects.map(unmapAssociatedObject)]))
}));
export const calculateSampleLineItemPrice = (invoiceCurrency, currencyLimits) => {
  const currencyLimit = currencyLimits.find(limit => limit.currencyCode === invoiceCurrency);
  const minimumAmountForPaymentMethod = (currencyLimit === null || currencyLimit === void 0 ? void 0 : currencyLimit.minimumAmountForPaymentMethod) || [];
  if (!currencyLimit || !minimumAmountForPaymentMethod.length) {
    return 1;
  }
  const highestMinimumAmount = Math.max(...minimumAmountForPaymentMethod.map(method => {
    var _method$amount;
    return (_method$amount = method === null || method === void 0 ? void 0 : method.amount) !== null && _method$amount !== void 0 ? _method$amount : 0;
  }));
  return Math.max(highestMinimumAmount, 1);
};
export const getCurrentDateInPortalTz = () => I18n.moment.portalTz().endOf('day').valueOf();
export const getInvoiceProperty = (invoice, propertyName) => {
  var _invoice$properties$p;
  return invoice === null || invoice === void 0 || (_invoice$properties$p = invoice.properties[propertyName]) === null || _invoice$properties$p === void 0 ? void 0 : _invoice$properties$p.value;
};
export const setInvoiceProperty = (invoice, propertyName, propertyValue) => {
  return Object.assign({}, invoice, {
    properties: Object.assign({}, invoice.properties, {
      [propertyName]: {
        name: propertyName,
        value: propertyValue
      }
    })
  });
};
export const getInvoiceAssociatedObjectProperty = (invoiceAssociatedObject, propertyName) => {
  var _invoiceAssociatedObj;
  return invoiceAssociatedObject === null || invoiceAssociatedObject === void 0 || (_invoiceAssociatedObj = invoiceAssociatedObject.properties[propertyName]) === null || _invoiceAssociatedObj === void 0 ? void 0 : _invoiceAssociatedObj.value;
};
export const getInvoiceSampleLineItem = invoice => {
  return invoice === null || invoice === void 0 ? void 0 : invoice.associatedObjects.INVOICE_TO_LINE_ITEM[0];
};