import { WORKFLOW_CREATE } from '../../constants/CopilotInAppActionTypes';
export const workflowCreate = ({
  action,
  query,
  triggeringCondition
}) => {
  return {
    data: {
      intent: WORKFLOW_CREATE,
      query,
      action,
      triggeringCondition
    }
  };
};